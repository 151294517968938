/* 
Small Only
@media screen and (max-width: 39.9375em) {}
 
Medium and up
@media screen and (min-width: 40em) {}

Medium only
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

Large and up
@media screen and (min-width: 64em) {}

Large only
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}

Using px sizing
Extra small devices (phones, 600px and down) 
@media only screen and (max-width: 600px) {...}

Small devices (portrait tablets and large phones, 600px and up) 
@media only screen and (min-width: 600px) {...}

Medium devices (landscape tablets, 768px and up)
@media only screen and (min-width: 768px) {...}

Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {...}

Extra large devices (large laptops and desktops, 1200px and up)
@media only screen and (min-width: 1200px) {...}

*/

.mvn_container {
 background-color: #fff;

 h1 {
        font-size: 1.5rem;
        font-weight: 900;
        line-height: 1em;
        color: $maven;
      }

  h2 {
        font-size: 1.0rem;
        font-weight: 700;
        color: $maven;
      }

  h3 {
    color: $maven;
  }

  h4, h5, h6 {
    color: $charcoal;
  }

  
  
  @media screen and (min-width: 40rem) {
    h1 {
        font-size: 2.5rem;
      }

    h2 {
        font-size: 1.9rem;
      }
    
  }
}

//*********************************

#mvnview {
  margin-top: 200px;
    
    @media screen and (min-width: 40rem) {
        margin-top: -50px;
        margin-bottom: 0;

   }

  
}

//*********************************


#mvnnav {
  top:0;
  left:0;
  padding-top:0;
  padding-bottom:0;
  margin: 0 100px 0 0;
  min-width: 100%;
  background: transparent;
  border: none;
  z-index:99;

  .top-bar-text{
    font-family: 'Raleway', Roboto, Arial, sans-serif;
    font-size: 2rem;
    color: $maven;
  }
 
  #top-bar{
    padding-top:0;
    padding-bottom:0;
  }

  .top-bar-container{
    min-width: 100%;
  }


  .top-bar{
    min-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background: transparent;
    opacity: 95%;
      
    .top-bar-left{
      max-width: 50%;
      padding-right: 5px;
      margin-right: 0px;
      float: left;
      vertical-align: baseline;
      img{
        width:75px;
      }

      h2{
        font-size: 2.4rem;

        margin-top:40px;
        margin-bottom:20px;
      }
      

    }

    .top-bar-center{
      max-width:70%;
      width:70%;
      padding-right: 1rem;
      text-align: center;

      color: $maven;
      font-size: 2rem;
      font-weight:300;
    }
     .top-bar-right{
      padding-top: 10px;
      background: transparent;
      opacity: 95%;
    }

    .support_button {
      background-color: $maven;
      border-radius: 10px;
    }

    .download_button {
      background-color: transparent;
      border: solid 1px #999;
      border-radius: 10px;
      color: $maven;
    }

    .menu {
      background-color: transparent;

      a:link {
        color: $maven;
        font-weight: 450;
      }

      a:visited {
        color: $maven;
        font-weight: 450;
      }

      a:hover {
        color: $maven;
        font-weight: 450;
      }

      a:active {
        color: $maven;
        font-weight: 450; 
      }
      
    .pg_selected {

      a:link {
        color: $maven;
        font-weight: bold;
      }

      a:visited {
        color: $maven;
        font-weight: bold;
      }

      a:hover {
        color: $maven;
        font-weight: bold;
      }

      a:active {
        color: $maven;
        font-weight: bold; 
      }
    } 

    }
    
    }

    @media screen and (min-width: 40rem) {
    position: relative; 

    .top-bar{
      .top-bar-left{
        max-width: 80px;
      }

    } 

    @media print {
       .top-bar{
        padding-top:0px;
        padding-bottom:0px;
        background-color: red;
        
        .top-bar-left{
        max-width: 80px;
        padding-right: 5px;
        margin-right: 0px;
        float: left;
        vertical-align: baseline;
        img{
          width:75px;
        }
    }
        }
    }
  }
}
//*********************************

#mvnForm{
  
}

.form_sneakpeak{
  width:100%; 
  padding:1rem 2rem 1rem 2rem;

  input[type="text"] {
    
    border:none;
    box-shadow: none;
    border-bottom: solid 1px $maven;
  }

   .button {
          width:40%;
          margin-top:3%;
          margin-right: 30%;
          margin-left: 30%;
          font-family: $header-font-family;
          font-size: 1rem;
          font-weight:900;
          background-color: $maven;
          border-radius:10px;
        }  
}

.sneak-peak-small{
  width:100%;
  .button{
    background-color: $maven;
    border-radius: 10px;
    padding:.4rem;
  }
}

//**************************************

.mvnInformation{
    padding-top: 20px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    min-height: 600px;

    ul li {
      font-size: 1.1em;
    }

    img{
      border-radius: 10px;
    }

      @media screen and (min-width: 40rem) {
        padding-top: 40px;
   }

   .release {
    font-size: 0.8em;
    h4 {
      color:$maven;
    }

    h5 {
      color:$maven;
    }

    .release-note {
      font-size: 1.2em;
    }

    hr{
      border-top: 1px solid $maven;

      }
    }
  }

//*********************************

  .block_maven {
    background-color:rgba(152, 12, 129, 0.1);
    border-radius: 10px;
    padding: 30px;
    font-family: 'Raleway', Roboto, Arial, sans-serif;
    font-size: 1.1em;
    font-weight: 700;
  }

  .block_grey {
    background-color:rgba(136, 136, 136, 0.1);
    border-radius: 10px;
    padding: 30px;
    font-family: 'Raleway', Roboto, Arial, sans-serif;
    font-size: 1.1em;
    font-weight: 700;
  }

//*********************************

.mvn_footer {
  background-color: $light-gray;
  color: $maven;
  width:100%;
  min-height: 70px;
  padding: 10px;
  margin:0;
  border: solid 1px $light-gray;

  a:link {
    color: $maven;
  }

  a:visited {
    color: $maven;
    font-weight: bold;
  }

  a:hover {
    color: $maven;
    font-weight: bold;
  }

  a:active {
    color: $maven;
  }

  .mvn_copy {
    font-size:.8rem;
    text-align: right;
    width: 100%;
    margin-right: auto;
    margin-left: auto;


    .copyright {
      margin-right: 0px;
      text-align: left;
      float: left;
    }

    ul {
      display: inline-block;
      float: right;
      li {
            display: inline-block;
      }
    }
  }

   @media screen and (min-width: 40rem) {
    padding: 20px;
    .mvn_copy {
      .copyright {
            margin-right: 100px;
            text-align: left;
            float: left;
      }


    }
   }

}

//*********************************

.announce {
  text-align: center;

  h1 {
        margin-left: 10px;
        padding-top: 20px;
        font-weight: 600;
        line-height: 1em;
        color: $maven;

      }

  img{
    display: block;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }

}


//*****************
// bata pages
.support {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1.0em;
  margin-left:auto;
  margin-right:auto;
  padding-left: 15px;

  max-width: 90%;

  h1 {
        font-size: 2.5rem;
        font-weight: 800;
      }


  a {
    color: $maven;
    font-weight: bold;
  }

  .support_button {
      background-color: $maven;
      border-radius: 10px;
    }


  .releases{
    margin-left: 10px;
    h3{
      color: $charcoal;
    }
  }

  li {
    margin-left:5px;
  }

  .maven {
      color: $maven;
      
    }
 
  .example {
    background-color: #FCFCFC;
    border: solid 1px #DDD; 
    border-radius: 10px; 
    padding: 5px 20px 10px 20px; 
    margin-top:20px;
    margin-bottom:10px;
    filter: drop-shadow(5px 8px 4px #DDD);
    width: 95%;

    h5 {
      font-size: 1.1em;
      font-weight: 800;
      color: $jet;

    }
  }

  .sidebar {
        background-color:rgba(152, 12, 129, 0.03);
        border-radius: 10px;
        border-left: solid 4px $maven;
        padding: 30px;
        font-family: 'Raleway', Roboto, Arial, sans-serif;
        font-weight: 700;
        width: 100%;

        ul li {
          list-style-type: square;
          margin-left:0;
          font-size: 0.9rem;
    }

     @media screen and (min-width: 40rem){
        margin-left:15px;
     } 
  }

  table {
    thead {
      position: sticky;
      top: 0;
      background-color: $maven;
      border: solid 1px $maven;



      th {
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: left;

        color: #ddd;
        
        h2 {
         color:#ddd;
        }
        
        .wider {
          width: 30%;
        }
      }
    }


    td {
      border: 1px solid #ddd;
      padding-top: 8px;
      padding-bottom: 8px;
      .wider {
      width: 30%;
      }
  
    }

    a {
      color: $maven; 
    }

    a:visited {
      color: #919191;
    }

     .group_title {
      background-color: $maven;
      color: #ddd;
      font-weight: 700;
      font-size: 1.0rem;
      opacity: 80%;
      text-align: left;
      z-index: -99;
    }

    tbody{
      tr.private{
        background-color: rgba(255, 212, 120, .2);
      }
      tr.category{
        background-color: rgba(131, 17, 112, .7);
        color: white;
        font-size: 1.1em;
        font-weight: 900;
      }
      tr:hover {
        //background-color:#F2EFED;
      }
    }
  }
}

//*********************************


.outline {
  border: solid 2px $orange;
}

.outline_mvn{
  border: solid 2px $maven;
}

.mvn_background{
  background-image: url('https://s3.amazonaws.com/media.smallcubed.com/images/maven/icons/Maven_Icon_512.png');
  opacity: 50%;
}


.spacing {
  background: transparent;
  height: 20px;
}

.spacing.two {
  background: transparent;
  height: 40px;
}

.spacing.three {
  background: transparent;
  height: 60px;
}

.spacing.four {
  background: transparent;
  height: 80px;
}

.spacing.five {
  background: transparent;
  height: 100px;
}

.width75{
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.width80{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.width85{
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.width90{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.width95{
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

//******* For markdown **************


img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


img.borderless {
  border:none;
}

img.shadow {
  box-shadow: 2px 2px 20px 10px rgba(0, 0, 0, 0.2);
}


.centered-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}


.charcoal {
  color: $charcoal;
}

.maven {
      color: $maven;
      
    }

.jet {
    color: $jet;
  }   

.change_type{
    font-weight: 800;
    font-style: italic;
    font-size: 1.2em;
  }

.selected {
  background-color:$maven;
  border-radius: 0px 0px 0 0;
  padding-top: 5px;

  color: #fff;
  a:link {
    color: #fff;
  }

  a:visited {
    color: #fff;
  }

  a:hover {
    color: #fff;
  }

  a:active {
    color: #fff;
  }

  @media screen and (min-width: 40em) {
    border-radius: 20px 20px 0 0;
  }

}

.selected_sub{
  background-color:$maven;
  padding-top: 5px;
  border-bottom: solid 1px $maven;
  color: $maven;

  a:link {
    color: #fff;
    font-weight: bold;
  }

  a:visited {
   color: #fff;
   font-weight: bold;
  }

  a:hover {
    color: #fff;
    font-weight: bold;
  }

  a:active {
    color: #fff;
    font-weight: bold;
  }

  @media screen and (min-width: 40em) {
    border-radius: 20px 20px 0 0;
  }

}

//*********************************

.view100vh{
  height: 100vh; 
}


.view90vh{
  height: 90vh; 
}

.view80vh{
  height: 80vh; 
}

.view70vh{
  height: 70vh; 
}
//*********************************


.pad5 {
  border-radius: 20px 20px 0 0;
  padding-top: 5px;
  border-bottom: solid 1px $maven;
}
 
.left10 {
    margin-left:10px;
}

.left25 {
  margin-left:25px;
}

.left50 {
  margin-left:50px;
}

.right10 {
    margin-right:10px;
}

.right25 {
  margin-right:25px;
}

.right50 {
  margin-right:50px;
}

.float-right {
  float: right;
}

//************************************************
.coming_soon {

$cs_badge-background: $maven;
$cs_badge-color: #fff;
$cs_badge-min-size: 11px;
$cs_badge-max-size: 15px;



.cs_badge {
  position: relative;
  letter-spacing: 0.08em;
  color: $cs_badge-color;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s ease;
  transform: rotate(-14deg);
  text-align: center;
  filter: drop-shadow(0.25em 0.7em 0.95em rgba(0,0,0, 0.8));
  /* min-size + (max-size - min-size) * ( (100vw - min-width) / ( max-width - min-width) ) */
  font-size: calc(#{$cs_badge-min-size} + #{($cs_badge-max-size - $cs_badge-min-size) / 1px} * ( (100vw - 420px) / ( 860) ));
  
  @media screen and (max-width: 420px) {
      font-size: $cs_badge-min-size;
  }
  
  @media screen and (min-width: 1280px) {
      font-size: $cs_badge-max-size;
  }
  
  
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    display: block;
    width: 10em;
    height: 10em;
    border-radius: 100%;
    background: $cs_badge-background;
    opacity: 0.9;
    transition: opacity 0.3s linear;
  }

  &:hover {
    color: $cs_badge-color;
    text-decoration: none;
    transform: rotate(-10deg) scale(1.05);

    &::before {
      opacity: 1.0;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    display: block;
    z-index: 0;
    width: 10em;
    height: 10em;
  }

  span {
    display: block;
    background: $cs_badge-background;
    margin-left: 20%;
    font-weight: bolder;
    border-radius: 0.4em;
    padding: 0.4em 1em;
    z-index: 1;
    min-width: 11em;
    border: 1px solid;
    text-transform: uppercase;
  }
}

}
//************************************************

 
 


$jet         : #999; //rgba(153, 153, 153)
$black       : #000;
$white       : #FFF;
$charcoal	 : #222; //rgba(34, 34, 34)
$orange		 : #d64937 ;
$orange1	 : #eb6e37;
$orange2	 : #f0530e;
$scblue      : #485468;
$sierra		 : #a45415;
$primary-color: #999;


$secondary-color: blue;
$maven_old: #92137b; // rgba(146, 19, 123)
$maven: #831170;  // rgba(131, 17, 112)
$maven_lt: rgba(131, 17, 112, .6);

// Internal variables used for colors

$body-font-family:'Maven Pro', Roboto, Arial, sans-serif;
$header-font-family: 'Raleway', Roboto, Arial, sans-serif;

$header-styles: (
  "small": (
    'h1': ('font-size': 24),
    'h2': ('font-size': 20),
    'h3': ('font-size': 19),
    'h4': ('font-size': 18),
    'h5': ('font-size': 17),
    'h6': ('font-size': 16),
  ),
  "medium": (
    'h1': ('font-size': 48),
    'h2': ('font-size': 40),
    'h3': ('font-size': 31),
    'h4': ('font-size': 25),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
);


$motion-ui-speeds: (
  default: 250ms,
  slow: 750ms,
  fast: 250ms,
) !default;

//Redmine Plugins
$scblue      : #485468;

#scs_prod{
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    min-height: 400px;

   h1{
      color:$scblue; 
      font-weight: bolder;
    } 

    h2{
      color:$scblue; 
      font-weight: bold;
    }

    h3{
      color:$scblue;
      font-weight: bold;
    }

    h5{
      font-weight: bold;
    }

  .product-header.zen {
  padding-top: 1.5em;

  @media screen and (min-width: 50rem) {
    .sidebar_left{
      border-left:solid 4px black;
      margin-left:15px;
      padding-left:10px;
    }
  } 
  

  }

  .prod_buy_large{
        position: relative;
        width:300px;
        height: 70px;
        padding-top:15px;
        padding-bottom:10px;
        border:5px solid $scblue;
        border-radius: 10px;
        text-align: center;
       
        color: $scblue;
        font-weight: bold;
        font-size: 1.3rem;
        opacity: 0.8;
        }

  .zen_release {
  margin:2.0em;
  }

}
//PRICING/RENEWAL***************************************

.sc_pricing_list {
  ul {
    font-size: 1.2rem;
    color: #222;  

    li {
      margin-left: 2.5rem;
      font-size: 1.2rem;
      color: #222; 
    }
  }
}

.sc_pricing{
  padding: 0;
  width:100%;
  margin: 0;


  hr{
    width: 95%;
    border:solid 1px $scblue;
    padding:0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .sc_price_outline_bl{
    border:none;
  }


  .sc_pricing_header{
    padding:10px;
    width:100%;
    text-align: center;
    font-weight:800;
    font-size:1.6rem;
    color: #222;
  }

  .sc_purchase_price{
    padding:10px;
    width:100%;
    height: 2.1em;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.6rem;
    color: $maven;
  }

  .sc_display_price{
    padding:10px;
    width:100%;
    height: 2.1em;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.6rem;
    color: $maven;
  }

  .key_text {
    font-family: "Courier"
  }  

  .sc_pricing_content{
    padding: 40px;
    font-size: 1.2rem;
    color: #222;

    h3{
      font-size: 1.6rem;
      font-weight: 500;
    }

    .refund{
      font-size: 0.8em;
      color: #222;
      text-align: center;
        
        a {
          color: $maven;
          font-weight:bolder;
        }
      
      a:hover{
        color: grey;
      }
    }
  }

  button{
    background-color: transparent;
    color:$maven;
    font-size: 1.8rem;
    text-shadow: 0.5px 0.5px;
    line-height: 2.0;
    font-weight: 900;
    height: 100%;
    width:100%;

  }

  .btn_purchase {
    color:$maven;
    font-size:1rem;
    font-weight: 600; 
    line-height: 1.1;   
    height: 100%;
    width:50%;
    margin-top:20px;
    margin-bottom:20px;
    margin-right:25%;
    margin-left:25%;
    padding:5px 15px;
    border: solid 2px #999;
    border-radius: 10px;
    position: relative;
    
    button{
    }
    
  }

  .quantity_desc {
    font-size: 0.75em;
    font-family: "Maven Pro";
  }

  .btn_purchase.disable, .btn_purchase.disable:hover {
    color: $medium-gray;
    background-color: $light-gray;
    border: solid 1px $medium-gray;
  }
    
  .btn_purchase:hover {
    background-color: rgba(131, 17, 112, .8);
    color:#fff;
    border: solid 2px rgba(131, 17, 112, .8);
  } 

  .btn_hidden {
    display: none;
  }

  .license{
  margin-top:10px;
  margin-left: 10px;
  height:100%;
  font-size: 1.4rem;
  line-height: 2.4;  

    input{
      margin:20px 0 0 0;
      padding:15px 10px;
      width:95%;
      border: solid 2px $scblue;
      border-radius:5px;
      font-size:1rem;
    }

    input:focus {
      border: solid 2px $scblue;
      background-color: transparent;
      outline: none;
      box-shadow:none;

    }

    .btn_renewal{
      margin:20px 0 0 0;
      padding:5px 15px;
      font-weight: bolder;
      font-size:1.1rem; 
      color:#fff;
      text-shadow: 0.5px 0.5px;
      background-color: $maven;
      border: solid 1px $maven;
      border-radius:5px;
      width:95%;
      
      button{
        color:#fff;
      }
      
    }
    
    .hidden {
      display: none;
    }
    
    #add_user_count {
      margin-bottom: -10px;
      
      p {
        margin: 0px;
      }
      
      #count_change {
        padding: 0px;
        padding-left: 0.25em;
        margin: 0px;
        display: inline-block;
        max-width: 70px;
        float: initial;
        text-align: center;
        border-color: $orange1;
      }
    }

    #action_choice {
      p {
        text-align: center;
        font-size: 0.8em;
        margin-top: 0px;
        margin-bottom: -10px;
      }
    }


    .btn_renewal:hover{
      background-color: rgba(131, 17, 112, .8);
      color:#fff;
      border: solid 1px rgba(131, 17, 112, .8);
    }
    
    #expiration_date {
      margin-bottom: 7px;
    }
  
  #coupon_info {
    font-size: 15px;
    font-weight: bold;
  }
  
  .renewal_details p {
    font-size: 1.0rem;
    line-height: 2.0;
    margin: 5.0px;
  }
  }

  .sc_renewal_price{
    margin-top:-1em;
    padding-bottom: 3em;
    width:100%;
    text-align: center;
    text-shadow: 0.5px 0.5px;
    font-weight:900;
    font-size:1.1rem;
    line-height:1.2;
    color: $maven;
  }

  .btn_download {
    font-size: 1.6rem;
    line-height:1.8;
    font-weight: bolder;
    color:#FBD54F;
    text-shadow: 0.5px 0.5px;
    padding:10px 20px 10px 20px;
    background-color: #005392;
    border: solid 2px #005392;
    border-radius: 5px;
    opacity: 0.7;
  }

 
  
  .btn_download:hover{
    background-color: #5A6982;
    color:#FBD54F;
    border: solid 2px #5A6982;
  } 

  p {
    padding:0;
    margin-top:7px;
    border:none;
    line-height: 2.2;
    font-size: 0.9em;
    width:100%;
    color: $scblue;
  }

  a:hover{
    color:#FF8450;
    font-weight: bolder;
    }


  .license_info {
      padding:10px;
      text-align: center;
      border: solid 2px $scblue;
      border-radius: 5px;
      width:100%;
  }

  #qualify-result {
    color: $alert-color;
    text-shadow: 0.5px 0.5px;
    font-weight: bolder;
  }

  .alert {
    color: $alert-color;
  }
  .warn {
    color: $orange1;
  }
  .info {
    color: #54B9A0;
    font-weight:bolder;
    text-shadow: 0.5px 0.5px;

  }

  @media screen and (min-width: 45rem){
  font-size:1.5rem;
    
    .sc_price_outline_bl{
      border: solid 2px $scblue;
    }
    .sc_pricing_header{
      text-align: left;
      font-size:1.9rem;
      float:left;
    }
    .sc_purchase_price{
        font-size:2rem;
       }
     .sc_display_price{
        font-size:2rem;
       }  
    .btn_purchase{
      font-size:1.2rem; 
      line-height: 1.5;  
      padding: 15px 10px;
    }
    .license{
      input{
        width:66%;
        float:left;
        font-size:1.4rem;
      }
    
      .btn_renewal{
        width:33%;
        font-size:1.5rem;
        margin-left: 5px;
      }

      .sc_renewal_price{
    margin-top:0;
    padding-bottom: 2em;
      }
    }
  }
}
//Support***************************

.support-header {
  margin-top:20px;
    
  h1{
    font-size: 3.0rem;
    font-weight: bold;
    line-height: 100%;
    text-align: left;
    color: $scblue;
  }
}

.support-sidebar{ 
    margin-top: 50px;
    width:250px;
    font-size: .4rem;
    margin-left: 0;
    padding-bottom: 10px;

  h1{ 
    font-size:2rem;
    font-weight: 900;

  }

  h2{
    font-size:1.7rem;
    font-weight: 700;

  }

  h3{
    font-size:1.5rem;
    font-weight: 500;

  }

  sign-in {
    margin-left: 5px;
    width:80%;
    border: 1px solid $scblue;
    color: $white;
    strong{
      color:white;
    }
  }

  button {
    margin-left: 5px;
    width:100%;
    background-color: $scblue;
    color: $white;
    strong{
      color:white;
    }
  }

  li {
    color: $charcoal;
    }

  @media screen and (min-width: 45rem) {
    margin-top: 20px;
  }  
}

.toc{
  background-color: #fefefe;
  border-left: solid 1px $scblue;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 0.9rem;

  p{
    font-size: .8rem;
  }

h1{
  font-size: 2rem;
}

h2{
  font-size: 1.25rem;
}

h3{
  font-size: 1.2rem;
}

h4{
  font-size: 1.25rem;
}


  hr{
   color: $scblue;
   margin-right: 10px;
  }


  a {
     color: $scblue;
  }

  a:hover {
      color: $charcoal;
  }


}

.support-content{
    font-size: 1.1rem;


     @media screen and (min-width: 45rem) {
      font-size: 1.4rem;

    }

    h1{
      font-size: 2.8rem;
      font-weight: 900;
      color: $scblue;
    }

    h2{
      font-size:2.2rem;
      font-weight: 700;
      color: $scblue;
    }

    h3{
      font-size:1.8rem;
      font-weight: 700;
      color: $scblue;
    }


    h4{
      font-size:1.5rem;
      font-weight: 500;
    }

    img {
      border: 2px solid #485468;
      margin-top: 10px;
      margin-bottom: 15px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .icon-img{
    img {
      width: 100px;
      float: left;
    }
  }
} 

.support_links{
  padding-right: 10px;
  line-height: 1.4;
  font-size: 1.3rem;
  color: #FBD54F;

  h4{
    color: #222;
    font-weight:bolder;

  }
  ul li {
    a {
          color: #999;
          font-weight:bold;
        }
      
      a:hover{
        color: #222;
      }
  }
}

//Older versions ***********************************************

.version {
  margin-left: -20px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 115%;
  font-size: .8rem;

  img{
    margin-left:auto;
    margin-right:auto;
  }

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    width: 11%;
  }

  td .wide, th .wide{
    width: 23%;
  }




  a {
    color: white; 
  }



  tr:nth-child(even){
    background-color:transparent;
  }

  .supported{
    
    td{
    padding-top:30px;
    padding-bottom: 30px;
    }
  }

   .unsupported {
    background-color: #485468;
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
  }

  tr:hover {
    background-color:transparent;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #485468;
    color: white;
    text-align: center;
  }

}

.multi_color_ordered_list{
  clear: both;
  list-style: none;
  

  li{
    margin-top:1em;
    padding-top: .7em;
    padding-left: 3em;
    
    counter-increment: inst;
    display: block;
    position: relative;
  } 

  li:before {
    content: counter(inst);
    left: -10px;
    top:0;
    height: 1.9em;
    width: 1.9em;
    position: absolute;
    
    padding-top:.15em;
    padding-left: auto;
    padding-right: auto;
    padding-bottom: auto;

    border-radius:50%;

    background-color:#000;


    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.5em;
    font-weight: 700;
         
  }

  li:nth-child(1):before {
      background-color:#7732D2;  
  }

  li:nth-child(2):before {
      background-color:#e92619;  
  }
  li:nth-child(3):before {
      background-color:#118d14;  
  }
  li:nth-child(4):before {
      background-color:#0626aa;  
  }
  li:nth-child(5):before {
      background-color:#FD7225;  
  }
}

.download {
    width: 100%;
    padding:0;
    margin:0;
    line-height: 50px;
    background-color: #eb6e37;
    color: white;
    text-align: center;
    font-weight: bolder;
    border-radius: 5px;
  } 



  .download:hover {
  color: #485468;
  }

.circle{

    background-color:#000;
    
    display:inline-block;
    padding-left:18px;
    padding-right:18px;

    text-align:center;
    color:#fff;
    font-size: 1rem;

    border-radius:50%;
  }

  .mypurple,.mypurple:hover{
    background-color:#7732D2!important;
    
  }
  
  .myred,.myred:hover{
    background-color:#e92619!important;
  }

  .mygreen,.mygreen:hover{
    background-color:#118d14!important;
  }

  .myblue,.myblue:hover{
    background-color:#0626aa!important;
  }

  .myorange,.myorange:hover{
    background-color:#FD7225!important;
  }

//Release notes ****************************
#scs_release {
  min-height: 200px;
  width: 100%;
  font-size: 1.5rem;

  h1{
    font-size: 2.3rem;
    font-weight: bold;
    color: $scblue;
    }

  h2{
    font-size:1.5rem;
    font-weight: bold;
    }

  h3{
    font-size:1.5rem;
    font-weight: bold;
    }

    ul li{
      font-size: .8em;
    }

  .zenapp{
  font-size: .6em;
    
    h1, h2, h3{
    color: $scblue;
    }
  }  

  p{
  font-size: 1.5rem;
  }

  p.release-date {
  margin-left:1.2em;
  top: -100px;
  }

  .release-download{
     width: 150px;
      margin-left:auto;
      margin-right:auto;
      border:5px solid #005392;
      background: #005392;
      border-radius: 5px;
      text-align: center;
      color: #FBD54F;
      font-weight: 900;
      font-size: 1.4rem;
      text-shadow: 0.8px 0.8px ;
      
  }

  @media screen and (min-width: 45rem) {
    margin-top:10px;

    #downloads{
      margin-top:-150px;
    }

    a.download{
      width: 10em;
      margin-bottom: 1.2em;
      
      padding: 5px 20px 5px 20px;
      background-color: $scblue;
      border:2px solid $scblue;
      border-radius: 5px;
      text-align: center;
      color: #FBD54F!important;
      font-weight: 900;
      font-size: 1.8rem;
      text-shadow: 0.8px 0.8px ;

    }   

    a.download:hover{
    background-color: #5A6982;
    color:#FBD54F!important;
    border: solid 2px #5A6982;
    } 
    
    a.download:visited{
    color:#FBD54F;
    } 

    }

  a:link {
    color: #000066;
  }

  a:visited {
    color: #000066;
  }

  a:hover {
    color: #000066;
  }

  a:active {
    color: #000066;
  }

  .change_type.new {
    color: green;
    font-weight: 800;
  }
  .change_type.fix {
    color: #a71a28;
    font-weight: 800;
  }
  .change_type.os {
    color: #5672d8;
    font-weight: 900;
  }

  .new_features div {
    padding-left: 1em;
    color: #565656;
    font-weight: 800;

    li{
      font-size:1.2;
    }
  }

  .release-container{
    .release-notes{
      margin: 0px;
      padding-left: 1.5em;
      padding-right: 1.5em;
    
      li{
        list-style: none;
        font-size: 1.2rem;
      }
    
      .release-title{
        font-size: 1.6rem;
      }
    }
  }
}
//SC Footer ****************************

#page_footer{
  margin:0;
  padding:0;
  width: 100%;

  z-index: 999;

#scs_footer {
  padding:0px;
  margin:0;
  width: 100%;
  background: $scblue;
  color: #999;
  border: none;

  .scs_footer-logo-inverted {
    padding-top: 0px;
    margin-top: 5px;
    margin-left: 15px;
    padding-bottom: 0px;
    width: 175px;
    }

  li {
    color: #999;
  }
}

  #sc_sub_footer {
    padding-top:10px;
    padding-bottom: 10px;
    margin:0;
    width: 100%;
    min-height: 100px;
    background: $light-gray;
    border: none;
    color: $scblue;
    font-size: 1.2rem;
    text-align:center;

    h3 {
      margin-left: 0;
      font-size: 1.2rem;
      line-height: 1.1rem;
      font-weight: bolder;
    }
    
    a:link {
      color: $scblue;
    }

    a:visited {
      color: $scblue;
    }

    a:hover {
      color: $scblue;
      font-weight: 600;
      letter-spacing: -0.06em;
    }
    a:active {
      color: #384155;
    }
    li {
      list-style-type: none; 
      color:$scblue; 
    }

    @media screen and (min-width: 45rem) {
      text-align:left;
      .center-column{
      margin-left: 10%;
      }
      .right-column{
      margin-left: 30%;
      }
    }
  }
}

// User for Zendesk sub management ****************************************************************************
#user_section {

  text-align: center;
  margin-bottom: 5.0em;
  margin-top: 4.0em;
  min-height: 480px;
  width: 100%;

  h1, h2{
    color: $scblue;
  }
  form {
    margin-top: 0;
    margin-bottom: 1.5em;
    
    p {
      margin-top: 0.5em;
      margin-bottom: 0.25em;
    }
    input[type="submit"], input[type="button"] {
      margin-top: 0.75em;
    }
    button{
      margin-top: 0.5em;
      padding:0.5em;
      border: solid 2px $scblue;
      border-radius: 10px;
      background-color: $scblue;
      color:  #F7FBF4;
      font-weight: bold;
      


    }
  }
  
  input, select {
    width: 370px;
    padding: 10px;
    margin-bottom: 0.4em;
    margin-left: auto;
    margin-right: auto;
  }

  label {
    text-align: right;
    line-height: 1.5em;
  }
  
  ul {
    list-style-type: none;
  }

  a:link {
    color: #000066;
  }

  a:visited {
    color: #000066;
  }

  a:hover {
    color: #000066;
    font-weight: 600;
    letter-spacing: -0.06em;
  }

  a:active {
    color: #000066;
  }
}

.panel {
  margin-top: 1.1em;
}

.nowrap {
    white-space: nowrap;
}

ul.emoji-list * {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    ul.emoji-list li {
      font-size: 1rem;
      float: left;
      display: inline-block;
      padding: 2px;
      margin: 4px;
    }
    img.emoji {
      cursor: pointer;
      height: 1rem;
      width: 1rem;
      margin: 0 .05em 0 .1em;
      vertical-align: -0.1em;
    }

#markdown{
  margin-top: 50px;
}

.notice {
  padding-top: 100px;
}

.product-header.zen {
  padding-top: 1.5em;
}

.zen_release {
  margin:2.0em;
}

.upgrade{
  width: 100%;
  
  
  .upgrade-content{
    width: 100%;
    border-radius: 5px;
  }
    
    .upgrade-text {
      color: $scblue;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: normal;
    }  


     @media screen and (min-width: 45rem) {

     }   
  }

//Other General ****************************
.outline_bl{
  border: solid 2px $scblue;
}
.highlight.orange{
    color: $orange;
    font-weight: 800;
}

.highlight.red {
    color: #a71a28;
    font-weight: 800;
    line-height: 0.8;
    }

 .highlight.red.normal {
    color: #a71a28;
    font-weight: 800;
  }

.highlight.green {
    color: green;
    font-weight: 800;
    }

.highlight.blue {
    color: #5672d8;
    font-weight: 800;
    }

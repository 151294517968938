#admin {
	width: 90%;
	margin-top: 1.5em;
	margin-bottom: 3em;
	margin-right: 190px;
	margin-left: 4.0em;

	h2{
		font-size: 1.5rem;
		font-weight: bold;
	}

	h3{
		font-size: 1.2rem;
		font-weight: bold;
	}

	.right-side {
		text-align: right;
	}
	select {
		width: 300px;
		clear: both;
	}
	p, td {
		color: $charcoal;
	}

	a {
		color: $charcoal;
		font-weight: bold;
	}
	a:hover {
		text-decoration: underline;
	}

	p.note {
		color: $warning-color;
	}

	p.error {
		color: $alert-color;
	}

	//Table formatting
	tr.sub-active {
		td.status {
			color: green;
		}
	}
	
	tr.hidden {
		display: none;
	}

	td.live-yes {
		color: green;
	}
	td.live-no {
		color: red;
	}
	
	td.not-found {
		text-align: center;
	}

	tr.sub-inactive {
		td {
			color: $dark-gray;
		}
		td.status {
			color: $alert-color;
		}
	}
	
	td button {
		background-color: smart-scale($medium-gray, -10%, 20%);
		color: white;
		border-color: smart-scale($dark-gray);
		border-width: 1px;
		border-style: solid;
		border-radius: 2px;
		padding: 2px;
		padding-left: 3px;
		padding-right: 3px;
		margin-top: 0.25em;
		margin-left: -2.0em;
		font-size: 0.8em;
		float: right;
	}

	span.processed {
		float: right;
		color: green;
	}
	
	td button.copy-button {
		background-color: transparent;
		border-color: none;
		border-width: 0px;
		border-style: none;
	}
	
	td span.zero-width {
		display: none;
		width: 0px;
	}

	//Form Formatting
	.admin_form{
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;

			
		label {
			text-align: right;
			font-weight: bold;
		}
		input {
			text-align: left;
			font-size: 0.9em;
			height: 1.8em;
		}
	}

	.results {
		margin-top: 1.5em;

		table {
			font-size: 0.9em;
		}
	}

	.fixed_width {
		font-family: courier, fixed-width;
		font-size: 0.8em;
		color: $charcoal;
	}

	.codes {
		margin-bottom: 1.25em;
	}

	button {
		background-color: smart-scale($medium-gray, -10%, 20%);
		color: white;
		border-color: smart-scale(darkGray);
		border-width: 2px;
		border-style: solid;
		border-radius: 4px;
		padding: 2px;
		padding-left: 3px;
		padding-right: 3px;
		margin-top: 0em;
		margin-left: 0px;
	}


}


#admin_sidebar {
	position: fixed;
	top: 100px;
	right: 10px;
	margin-bottom: 200px;
	min-width: 180px;
	max-width: 190px;
	background-color: darkGray;
	border-radius: 10px;
	border: 2px solid;
	border-color: $dark-gray;
	padding: 0.5em;

	ul {
		list-style-type: none;
	}

	p, li, a {
		color: $white;
	}
}
/* FastSpring styling for store pages */

 #store {
  max-width: 85rem;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  color:#999;

    #mail-suite{
    }


    #mail-bundles{
    }
  
  .bundles {
        margin-top:-5px;
        margin-left:20px;
        font-size: 2em;
    } 

  store::before, store::after {
    content: ' ';
    display: table;
    flex-basis: 0;
    order: 1; }

  store::after {
    clear: both; }

    
	 ul.unordered {	
	   	list-style-type: none;
	   }

	img.icon{
		width:15%;
	}

	
    .product{
        img{
            width:30%;
        }
        margin-top: 3em;
    }


    .product-tlv{
        img{
            width:30%;
        }
		margin-top: 3em;


    @media screen and (min-width: 64em) {
         img{
            width:15%;
        }
    }


    }

	.product-info{
		max-width:65%;
        float:right;
	}

    .product-info-tlv{

        width:80%;
        float:right;
    }

	.volume-pricing{
		margin-top:-5px;
		margin-left:20px;
	}

	.description{
		font-size:110%;
	 }
}

/* FS Styles */

html, body, div, dl, dt, dd, ul, ol, li, pre, form, fieldset,
input, p, blockquote, th, td {
    color: #999;

}


img, body, html {
    border: 0;
}


#lower-security {
    text-align: right;
    padding-right: 1em;
    height: 100px;
    background: url(images/fs_secure-ordering.png) no-repeat right;
}

/* End hide from IE-mac */
.core-test-annotation {
    display: none;
}

.core-dialog {
    padding: 1em;
}

.core-section {
    clear: both;
}

.core-section + .core-section {
    margin-top: 2em;
}

.core-js-disabled-block {
    display: block;
}

.core-js-enabled-block {
    display: none;
}

.core-js-disabled-inline {
    display: inline;
}

.core-js-enabled-inline {
    display: none;
}

.core-js-support .core-js-disabled-block {
    display: none;
}

.core-js-support .core-js-enabled-block {
    display: block;
}

.core-js-support .core-js-disabled-inline {
    display: none;
}

.core-js-support .core-js-enabled-inline {
    display: inline;
}

.core-messages {
    padding: .5em;
    border: 1px solid #edebcd;
    background-color: #fefdec;
    margin-bottom: .25em;
    color: #999;
}

.core-message-warn, .core-message-error, .core-message-fatal {
    color: #d40e0e;
}

.core-message-info {
    color: #999;
}

.core-form-field-group {
    clear: both;
    width: 100%;
}

.core-form-field-area-left {
    width: 40%;
    float: left;
}

.core-form-field-area-right {
    margin-left: 45%;
    width: 50%;
}

.core-form-field + .core-form-field {
    margin-top: .75em;
}

.core-form-field-label {
    margin-bottom: .25em;
    font-size: .9em;
    color: #999;
}

.core-form-field-body-note {
    font-size: .9em;
    color: #999;
}

.core-form-field-required .core-form-field-label {
    color:#999;
    font-weight: bold;
}

label {
    white-space: nowrap;
}

.store-footer {
    padding-top: 2em;
    font-size: .8em;
    color: #555;
    text-align: center;
}

.store-tools {
    margin: 1em 0;
    text-align: right;
}

.store-action-input {
    font-size: .9em;
}

.store-action-command + .store-action-input {
    margin-left: .25em;
}

.store-action-prev-li {
    display: none;
}

.store-product-price-original {
    text-decoration: line-through;
}

.store-product-price-discount-amount {
    display: none;
}

.store-product-price-discount-percent {
    display: none;
}

.store-survey .core-form-field-input-text {
    width: 20em;
}

.store-style-hide-field-coupon .store-order-action-coupon-li {
    display: none !important;
}

.store-style-hide-field-company .store-field-contact-company {
    display: none !important;
}

.store-style-hide-item-action-delete .store-order-item-action-delete-li {
    display: none !important;
}

.store-style-hide-item-action-quantity .store-order-item-action-quantity-li {
    display: none !important;
}

.store-style-hide-action-edit .store-order-action-edit-li {
    display: none !important;
}

.store-page-navigation {
    text-align: right;
    padding-top: 1em;
    border-top: 1px dotted #ddd;
    margin-top: 2em;
    margin-bottom: 2em;
    clear: both;
}

.store-page-navigation .store-action-list {
    text-align: right;
}

.store-page-navigation .store-action-list li {
    display: inline;
    margin: 0;
    padding: 0;
    text-align: right;
    white-space: nowrap;
    margin-left: .5em;
}

.store-page-navigation .store-action-list .store-action-prev-li {
    display: none;
}

.store-section-vat-exemption .store-order-price-value {
    font-size: .9em;
}

.store-section-vat-exemption .store-order-price-value .store-action-input {
    text-align: right;
    max-width: 10em;
}

.store-field-cc-number input {
    width: 16em;
}

.store-field-cc-security-code-about {
    display: none;
}

#store-processing-element {
    padding-left: 230px;
    background: url(//dcnz2rrcot657.cloudfront.net/progress/bar.gif) no-repeat left center;
    font-weight: bold;
    font-size: 16px;
}

.store-product-detail {
    clear: both;
}

.store-product-detail + .core-section {
    margin-top: 2em;
}

.store-product-detail-title {
    display: inline;
}

.store-product-detail-image {
    float: left;
}

.store-product-detail-image img {
    padding: .5em;
    margin-right: .5em;
    margin-bottom: 2em;
    width: 85%;
}

.store-product-detail-image-empty {
    display: none;
}

.store-product-detail-description-pricing {
    display: none;
    margin-top: 1em;
}

.store-product-detail-description-short {
    margin-top: 1em;
    font-size: 1.2em;
}

.store-product-detail-description-short p + p {
    margin-top: 1em;
}

.store-product-detail-pricing {
    display: block;
}

.store-product-detail-offer {
    margin: 0;
    padding-right: .5em;
}

.store-product-detail-quantity .core-form-field-label {
    display: inline;
    color: #999;
}

.store-product-detail-quantity .core-form-field-body {
    display: inline;
    padding-left: .25em;
}

.store-product-detail-action-list {
    text-align: right;
}

.store-product-detail-action-list li {
    display: inline;
    margin: 0;
    padding: 0;
    text-align: right;
    white-space: nowrap;
    margin-left: .5em;
}

.store-style-product-detail-inline-volume-pricing .store-product-detail-description-pricing {
    display: block;
}

.store-style-product-detail-inline-volume-pricing .store-product-detail-offer-volume {
    display: none;
}

.store-product-list-item {
    clear: both;
    margin-bottom: 1em;
}

.store-product-list-item + .store-product-list-item {
    padding-top: 1em;
    border-top: 1px dotted #ddd;
}

.store-product-list-item-title {
    display: inline;
}

.store-product-list-item-image {
    float: left;
}

.store-product-list-item-image img {
    padding: .5em;
    margin-right: .5em;
}

.store-product-list-item-image-empty {
    display: none;
}

.store-product-list-item-pricing {
    display: inline;
}

.store-product-list-item-offer {
    margin: 0;
    margin-right: .5em;
    display: inline;
}

.store-product-list-item-action-list {
    clear: both;
    margin-top: 1em;
    text-align: right;
}

.store-product-list-item-action-list li {
    display: inline;
    margin: 0;
    padding: 0;
    text-align: right;
    white-space: nowrap;
    margin-left: .5em;
}

.store-style-single .store-product-list-item-action-add {
    display: none;
}

.store-style-multi .store-product-list-item-action-order {
    display: none;
}

.ext-ie .store-product-option-groups {
    clear: both;
}

.store-product-option-groups + .core-section {
    margin-top: 2em;
}

.store-product-option-group-description {
    margin-left: .50em;
    margin-bottom: 1em;
    font-size: .90em;
}

.store-product-option-group-body {
    margin-left: .50em;
    font-size: .90em;
}

.ext-gecko .store-product-options-check input {
    vertical-align: text-bottom;
}

.ext-gecko .store-product-options-radio input {
    vertical-align: text-bottom;
}

.store-product-option + .store-product-option {
    margin-top: .75em;
}

.store-product-option-input-quantity {
    width: 2em;
}

.store-product-option-display {
    padding-left: 1em;
    padding-right: 1em;
}

.store-product-option-price-original {
    text-decoration: line-through;
    padding-right: .5em;
}

.store-product-option-shipping {
    font-size: .90em;
}

.store-product-option-description, .store-product-option-description-offers {
    font-size: .90em;
    margin-left: 1em;
    margin-top: .25em;
}

.store-product-option-offer {
    margin: 0;
    padding-right: .5em;
    display: inline;
}

.store-payment-method-list {
    margin-top: 1em;
}

.store-payment-method-list-selection {
    padding-right: .5em;
}

.store-payment-method-list li + li {
    margin-top: 1em;
}

.store-payment-method-title {
    display: none;
}

.store-payment-method-po-reference {
    margin-left: 1.5em;
    margin-top: .5em;
    font-size: .9em;
}

.store-field-contact-full-name input {
    width: 16em;
}

.store-field-contact-first-name input {
    width: 16em;
}

.store-field-contact-last-name input {
    width: 16em;
}

.store-field-contact-phone input {
    width: 16em;
}

.store-field-contact-company input {
    width: 16em;
}

.store-field-contact-email input {
    width: 16em;
}

.store-field-contact-email .core-form-field-body-note {
    width: 19em;
}

.store-field-address-country select {
    width: 16em;
}

.store-field-address-street input {
    width: 16em;
}

.store-field-address-street + .store-field-address-street2 {
    margin-top: .25em;
}

.store-field-address-street2 .core-form-field-label {
    display: none;
    color: #999;
}

.store-field-address-street2 input {
    width: 16em;
}

.store-field-address-region input {
    width: 16em;
}

.store-field-address-region select {
    width: 16em;
}

.store-field-address-postal input {
    width: 16em;
}

.store-field-address-city input {
    width: 16em;
}

.store-order-pricing {
    clear: both;
    margin-top: 2em;
}

.store-order-price {
    text-align: right;
    clear: both;
}

.store-order-price-label {
    font-weight: bolder;
    width: 85%;
    float: left;
}

.store-order-price-value {
    margin-left: 15%;
}

.store-order-action-list {
    text-align: left;
    margin-top: 2em;
}

.store-order-action-list li {
    display: inline;
    padding: 0;
    margin: 0;
    text-align: left;
    margin-right: .5em;
    white-space: nowrap;
}

.store-page-navigation .store-order-action-list {
    text-align: right;
    margin-top: 0;
}

.store-page-navigation .store-order-action-list li {
    text-align: right;
    margin-left: .5em;
}

.store-order-action-coupon .store-action-input {
    width: 6em;
    font-weight: bold;
}

.store-order-action-list li.store-order-action-quantity-li {
    display: none;
}

.store-style-order-level-quantity-update .store-order-action-list li.store-order-action-quantity-li {
    display: inline;
}

.store-style-order-level-quantity-update .store-order-item-action-quantity .store-action-command {
    display: none;
}

.store-order-items {
    clear: both;
}

.store-order-item-group {
    clear: both;
    margin-bottom: 1em;
}

.store-order-item-group + .store-order-item-group {
    margin-top: 2em;
    padding-top: 1em;
    border-top: 1px dotted #eee;
}

.store-order-item-group-action-list {
    margin-top: .5em;
    text-align: right;
}

.store-order-item-group-action-list li {
    display: inline;
    margin: 0;
    padding: 0;
    text-align: right;
    white-space: nowrap;
    margin-left: .5em;
}

.store-order-item-quantity {
    padding-right: 1em;
}

.store-order-item-quantity-single {
    display: none;
}

.store-order-item-description {
    font-size: .9em;
    margin-left: .5em;
    clear: both;
}

.store-order-item-child .store-order-item-description {
    font-size: 1.0em;
    margin-left: 0;
}

.store-order-item-offers {
    font-size: .85em;
}

.store-order-item-offer {
    display: inline;
}

.store-order-item-price-zero {
    visibility: hidden;
}

.store-order-item-price-original {
    text-decoration: line-through;
    padding-right: .5em;
}

.store-order-item-base-title {
    font-size: 1.5em;
    font-weight: 500;
    float: none;
}

.store-order-item-base-pricing {
    text-align: right;
}

.store-order-item-child {
    padding-left: 1em;
    font-size: .9em;
}

.store-order-item-child-title {
    float: left;
}

.store-order-item-child-pricing {
    text-align: right;
}

.store-order-item-action-quantity .store-action-input {
    width: 2.5em;
}

.store-notice-reseller {
    padding: 2px;
}

.store-notice-bill-descriptor {
    border: 1px dotted #ccc;
    background-color: #eee;
    font-weight: bolder;
    padding: 1px;
    padding-left: 4px;
    padding-right: 4px;
    color: #999;
}

.store-value-money-real {
    display: none;
}

.store-value-money-about {
    display: none;
}

.store-value-money-help {
    cursor: help;
    border-bottom: 1px dotted blue;
}

.store-product-price-zero, .store-product-price-original-zero,
.store-product-option-price-zero, .store-product-option-price-original-zero {
    display: none;
}

.store-product-pricing-tiered-table .store-product-price-zero,
.store-product-pricing-tiered-table .store-product-option-price-zero {
    display: inline;
}

.store-tooltip {
    position: absolute;
    z-index: 9000;
    top: 0;
    left: 0;
    font-size: .9em;
    padding: .75em;
    max-width: 400px;
    background-color: white;
    color: #999;
    border: 1px solid #969696;
    -webkit-box-shadow: #c8c8c8 1px 1px 1px;
    -moz-box-shadow: #c8c8c8 1px 1px 1px;
    -webkit-border-radius: .25em;
    -moz-border-radius: .25em;
}

.store-tooltip-content {
    display: none;
}

#store-variation-selection {
    display: block;
    position: relative;
}

#store-variation-selection-body {
    position: absolute;
    z-index: 100;
    background-color: #efefef;
    color: #999;
    right: 0;
    height: 16em;
    width: 50%;
    padding: .25em;
    text-align: left;
}

#store-variation-selection-body h3 {
    color: #999;
}

#store-variation-selector-command {
    text-align: right;
    display: inline;
    font-size: 1em;
    white-space: nowrap;
    text-decoration: none;
}

#store-variation-selection-variations {
    font-size: 1em;
    height: 16em;
    overflow: auto;
    background-color: white;
}

.store-variation-select-item-list {
    margin: 1em;
}

.store-variation-select-item-list li a {
    display: block;
    font-size: .9em;
    color: #999;
    text-decoration: underline;
}

.store-variation-select-item-list li a:hover {
    background-color: black;
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.store-style-inline-form .core-form-field-area-left {
    float: none;
    width: 100%;
}

.store-style-inline-form .core-form-field-area-right {
    margin-left: 0;
    width: 100%;
}

.store-style-inline-form .core-form-field-group-body .core-form-field-label {
    float: left;
    width: 30%;
    text-align: right;
    margin: 0;
    color: #999;
}

.store-style-inline-form .core-form-field-group-body .core-form-field-body {
    margin: 0;
    margin-left: 30%;
    padding: 0;
    padding-left: .5em;
    text-align: left;
    color:#999;
}

.store-style-inline-form .core-form-field-required .core-form-field-label {
    font-weight: bold;
    color: #999;
}

.store-style-inline-form .core-form-field-label:after {
    content: ':';
    color: #999;
}

.store-style-inline-form .core-form-field-label label, .core-form-field-label {
    white-space: nowrap;
    color: #999;
}

.store-style-inline-form .core-form-field-body .core-form-field {
    margin-bottom: .5em;
}

.store-style-inline-form .core-form-field-group-body .core-form-field {
    clear: both;
}

.store-style-digital-only .store-field-address-street2 {
    display: none;
}

.store-decorate-chameleon .store-action .store-action-command {
    text-decoration: none;
}

.store-decorate-chameleon .store-action .store-action-command:hover {
    text-decoration: none;
}

.store-decorate-chameleon .core-message-warn, .store-decorate-chameleon .core-message-error,
.store-decorate-chameleon .core-message-fatal {
    color: #d40e0e;
    padding-left: 11px;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/red/exclamation.gif) no-repeat center left;
}

.store-decorate-chameleon .core-message-info {
    color: #999;
    padding-left: 11px;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/blue/info.gif) no-repeat center left;
}

.store-decorate-chameleon .store-action-view-help .store-action-title {
    padding-left: 9px;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/black/question.gif) no-repeat center left;
}

.store-decorate-chameleon #store-variation-selection-current {
    padding-right: 12px;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/green/suitcase.gif) no-repeat center right;
}

.store-decorate-chameleon .store-product-list-item-offer-volume,
.store-decorate-chameleon .store-product-detail-offer-volume {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/black/calculator.gif) no-repeat center left;
}

.store-decorate-chameleon .store-order-action-edit .store-action-title {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/black/edit.gif) no-repeat center left;
}

.store-decorate-chameleon .store-order-action-more .store-action-title {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/black/shopping_cart.gif) no-repeat center left;
}

.store-decorate-chameleon .store-order-action-order .store-action-title {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/green/arrow_right.gif) no-repeat center left;
}

.store-decorate-chameleon .store-order-action-coupon .store-action-title {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/blue/bookmark.gif) no-repeat center left;
}

.store-decorate-chameleon .store-order-item-action-edit .store-action-title {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/black/edit.gif) no-repeat center left;
}

.store-decorate-chameleon .store-product-detail-action-save .store-action-title {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/green/check.gif) no-repeat center left;
}

.store-decorate-chameleon .store-order-item-action-delete .store-action-title {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/red/trash.gif) no-repeat center left;
}

.store-decorate-chameleon .store-order-item-action-quantity {
    padding-left: 13px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/black/calculator.gif) no-repeat center left;
}

.store-decorate-chameleon .store-notice-security {
    display: inline;
    padding-left: 11px;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/black/locked.gif) no-repeat center left;
}

.store-decorate-chameleon .store-notice-bill-descriptor-message {
    padding-left: 11px;
    background: url(//dcnz2rrcot657.cloudfront.net/chameleon/mini/blue/warning.gif) no-repeat center left;
}

.store-decorate-chameleon .store-offer-file-backup-cd .store-order-item-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cd_add.png) no-repeat center left;
}

.store-decorate-chameleon .store-offer-file-backup-digital .store-order-item-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/drive_add.png) no-repeat center left;
}

.store-decorate-pay .store-payment-method-cc .store-payment-method-title,
.store-decorate-pay .store-payment-method-pp .store-payment-method-title {
    display: inline;
    padding-left: .25em;
}

.store-decorate-pay .store-payment-method-cc .store-payment-type-title,
.store-decorate-pay .store-payment-method-pp .store-payment-type-title,
.store-decorate-pay .store-field-cc-number .store-payment-types .store-payment-type-title {
    display: inline-block;
    visibility: hidden;
    width: 1px;
}

.store-decorate-pay .store-field-cc-number .core-form-field-body-note {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    padding-top: 1px;
}

.store-decorate-pay .store-payment-type-visa {
    padding-left: 28px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/visa_16.png) no-repeat center left;
}

.store-decorate-pay .store-payment-type-mastercard {
    padding-left: 28px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/mastercard_16.png) no-repeat center left;
}

.store-decorate-pay .store-payment-type-discover {
    padding-left: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/discover_16.png) no-repeat center left;
}

.store-decorate-pay .store-payment-type-pp {
    padding-left: 28px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/paypal_16.png) no-repeat center left;
}

.store-decorate-pay .store-payment-type-maestro {
    padding-left: 28px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/maestro_16.png) no-repeat center left;
}

.store-decorate-pay .store-payment-type-amex {
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/amex_16.png) no-repeat center left;
}

.store-decorate-pay .store-payment-type-giro {
    padding-left: 37px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/giro_16.png) no-repeat center left;
}

.store-decorate-pay .store-payment-type-solo {
    padding-left: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/solo_16.png) no-repeat center left;
}

.store-decorate-pay2 .store-payment-method-cc .store-payment-method-title,
.store-decorate-pay2 .store-payment-method-pp .store-payment-method-title {
    display: none;
}

.store-decorate-pay2 .store-payment-method-cc .store-payment-type-title,
.store-decorate-pay2 .store-payment-method-pp .store-payment-type-title,
.store-decorate-pay2 .store-field-cc-number .store-payment-types .store-payment-type-title {
    display: inline-block;
    visibility: hidden;
    width: 1px;
}

.store-decorate-pay2 .store-payment-method-cc-li, .store-decorate-pay2 .store-payment-method-pp-li {
    height: 24px;
    font-size: 24px;
    line-height: 24px;
}

.store-decorate-pay2 .store-payment-method-cc-li input, .store-decorate-pay2 .store-payment-method-pp-li input {
    height: 24px;
}

.store-decorate-pay2 .store-field-cc-number .core-form-field-body-note {
    font-size: 26px;
    line-height: 26px;
    height: 26px;
    padding-top: 1px;
}

.store-decorate-pay2 .store-payment-method-list-now li + li {
    margin-top: 16px;
}

.store-decorate-pay2 .store-payment-type-visa {
    padding-left: 42px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/visa_24.png) no-repeat center left;
}

.store-decorate-pay2 .store-payment-type-mastercard {
    padding-left: 42px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/mastercard_24.png) no-repeat center left;
}

.store-decorate-pay2 .store-payment-type-discover {
    padding-left: 42px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/discover_24.png) no-repeat center left;
}

.store-decorate-pay2 .store-payment-type-amex {
    padding-left: 42px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/amex_24.png) no-repeat center left;
}

.store-decorate-pay2 .store-payment-type-pp {
    padding-left: 42px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/paypal_24.png) no-repeat center left;
}

.store-decorate-pay3 .store-payment-method-cc .store-payment-type-title,
.store-decorate-pay3 .store-payment-method-pp .store-payment-type-title,
.store-decorate-pay3 .store-field-cc-number .store-payment-types .store-payment-type-title {
    display: none;
}

.store-decorate-pay3 .store-field-cc-number .core-form-field-body-note {
    padding-top: 1px;
}

.store-decorate-pay3 .store-payment-type-visa {
    display: inline-block;
    height: 19px;
    width: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/3/visa.png) no-repeat center left;
}

.store-decorate-pay3 .store-payment-type-mastercard {
    display: inline-block;
    height: 19px;
    width: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/3/mastercard.png) no-repeat center left;
}

.store-decorate-pay3 .store-payment-type-discover {
    display: inline-block;
    height: 19px;
    width: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/3/discover.png) no-repeat center left;
}

.store-decorate-pay3 .store-payment-type-amex {
    display: inline-block;
    height: 19px;
    width: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/3/american_express.png) no-repeat center left;
}

.store-decorate-pay3 .store-payment-type-jcb {
    display: inline-block;
    height: 19px;
    width: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/3/jcb.png) no-repeat center left;
}

.store-decorate-pay3 .store-payment-type-pp {
    display: inline-block;
    height: 19px;
    width: 27px;
    background: url(//dcnz2rrcot657.cloudfront.net/pay/3/paypal.png) no-repeat center left;
}

.store-decorate-silk input[type=text] {
    padding: 1px 3px;
    background: #fff url(//dcnz2rrcot657.cloudfront.net/ext/2.0/resources/images/default/form/text-bg.gif) repeat-x 0 0;
    border: 1px solid #B5B8C8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.store-decorate-silk input[type=text]:focus {
    border: 1px solid #7eadd9;
}

.store-decorate-silk .store-action {
    font-size: 14px;
}

.store-decorate-silk .store-action .store-action-command {
    text-decoration: none;
}

.store-decorate-silk .store-action .store-action-command:hover {
    text-decoration: none;
}

.store-decorate-silk .core-message-warn, .store-decorate-silk .core-message-error,
.store-decorate-silk .core-message-fatal {
    color: #d40e0e;
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/exclamation.png) no-repeat center left;
}

.store-decorate-silk .core-message-info {
    color: #999;
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/information.png) no-repeat center left;
}

.store-decorate-silk .store-action-view-help .store-action-title {
    background: url(//dcnz2rrcot657.cloudfront.net/silk/help.png) no-repeat center left;
    width: 16px;
    text-indent: -9000px;
    text-align: left;
    display: inline-block;
}

.store-decorate-silk .store-action-view-help a {
    text-decoration: none !important;
}

.store-decorate-silk .store-input-cc-security-code {
    padding-right: 28px;
    background: url(//dcnz2rrcot657.cloudfront.net/cvv/cvv_generic_small.png) no-repeat center right;
}

.store-decorate-silk .store-product-list-item-offer-volume, .store-decorate-silk .store-product-detail-offer-volume {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/calculator.png) no-repeat center left;
}

.store-decorate-silk .store-product-list-item-action-add .store-action-title,
.store-decorate-silk .store-product-detail-action-add .store-action-title,
.store-decorate-silk .store-product-list-offer-item-action-add .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cart_add.png) no-repeat center left;
}

.store-decorate-silk .store-product-list-item-action-order .store-action-title,
.store-decorate-silk .store-product-detail-action-order .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cart_go.png) no-repeat center left;
}

.store-decorate-silk .store-product-list-item-action-delete .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cart_delete.png) no-repeat center left;
}

.store-decorate-silk .store-product-detail-action-save .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/accept.png) no-repeat center left;
}

.store-decorate-silk .store-product-list-offer-item-action-select .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/misc/check/round_off.png) no-repeat center left;
}

.store-decorate-silk .store-product-list-offer-item-action-deselect .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/misc/check/round_on.png) no-repeat center left;
}

.store-decorate-silk .store-action-next .store-action-title {
    padding-right: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/bullet_go.png) no-repeat center right;
}

.store-decorate-silk .store-action-complete .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/accept.png) no-repeat center left;
}

.store-decorate-silk .store-order-action-edit .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/pencil.png) no-repeat center left;
}

.store-decorate-silk .store-order-action-more .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cart_put.png) no-repeat center left;
}

.store-decorate-silk .store-order-action-order .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cart_go.png) no-repeat center left;
}

.store-decorate-silk .store-order-action-coupon .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/tag_blue.png) no-repeat center left;
}

.store-decorate-silk .store-order-action-quantity .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/calculator.png) no-repeat center left;
}

.store-decorate-silk .store-order-item-action-edit .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/pencil.png) no-repeat center left;
}

.store-decorate-silk .store-order-item-action-delete .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cart_delete.png) no-repeat center left;
}

.store-decorate-silk .store-order-item-action-quantity .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/calculator.png) no-repeat center left;
}

.store-decorate-silk .store-order-item-subscription .store-order-item-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/arrow_refresh.png) no-repeat center left;
}

.store-decorate-silk .store-subscription-update-payment .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/arrow_right.png) no-repeat center left;
}

.store-decorate-silk .store-subscription-cancel .store-action-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cross.png) no-repeat center left;
}

.store-decorate-silk .store-subscription-entry-success .store-subscription-entry-date {
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/bullet_green.png) no-repeat center left;
}

.store-decorate-silk .store-subscription-entry-new .store-subscription-entry-date {
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/bullet_green.png) no-repeat center left;
}

.store-decorate-silk .store-subscription-entry-pending .store-subscription-entry-date {
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/bullet_white.png) no-repeat center left;
}

.store-decorate-silk .store-subscription-entry-fail .store-subscription-entry-date {
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/bullet_black.png) no-repeat center left;
}

.store-decorate-silk .store-subscription-entry-next .store-subscription-entry-date {
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/bullet_white.png) no-repeat center left;
}

.store-subscription-entry-next {
    font-style: italic;
}

.store-subscription-action-list {
    clear: both;
    margin-top: 1em;
    text-align: left;
}

.store-subscription-action-list li {
    display: inline;
    margin: 0;
    padding: 0;
    text-align: left;
    white-space: nowrap;
    margin-right: .5em;
}

.store-subscription-entry {
    clear: both;
    padding-left: .5em;
    padding-right: 1em;
    padding-bottom: 1em;
}

.store-subscription-entry + .store-subscription-entry {
    padding-top: 1em;
    margin-top: 1em;
    border-top: 1px dotted #eee;
}

.store-subscription-entry-date {
    float: left;
    margin-right: 6em;
}

.store-subscription-entry-status {
    float: left;
    margin-right: 4em;
}

.store-subscription-entry-amount {
    float: right;
}

.store-decorate-silk .store-notice-security {
    display: inline;
    padding-left: 13px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/small/lock.png) no-repeat center left;
}

.store-decorate-silk .store-notice-bill-descriptor-message {
    padding-left: 19px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/information.png) no-repeat center left;
}

.store-decorate-silk .store-offer-file-backup-cd .store-order-item-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cd_add.png) no-repeat center left;
}

.store-decorate-silk .store-offer-file-backup-digital .store-order-item-title {
    padding-left: 19px;
    white-space: nowrap;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/drive_add.png) no-repeat center left;
}

.store-decorate-silk #store-variation-selector-command {
    padding-right: 19px;
    font-size: 14px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/extra/control_down.png) no-repeat center right;
}

.store-decorate-silk #store-variation-selector-command:hover {
    padding-right: 19px;
    font-size: 14px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/extra/control_down_blue.png) no-repeat center right;
}

.store-decorate-silk #store-variation-selector-command.store-variation-selector-active {
    padding-right: 19px;
    font-size: 14px;
    background: url(//dcnz2rrcot657.cloudfront.net/silk/cross.png) no-repeat center right;
}

.store-decorate-button .store-page-navigation .store-action-command,
.store-decorate-button .store-product-list-item-action-list .store-action-command,
.store-decorate-button .store-page-navigation .store-action-command:hover,
.store-decorate-button .store-product-list-item-action-list .store-action-command:hover,
.store-decorate-button .store-page-navigation .store-action-command:active,
.store-decorate-button .store-product-list-item-action-list .store-action-command:active,
.store-decorate-button .store-page-navigation .store-action-command:link,
.store-decorate-button .store-product-list-item-action-list .store-action-command:link,
.store-decorate-button .store-page-navigation .store-action-command:visited,
.store-decorate-button .store-product-list-item-action-list .store-action-command:visited,
.store-decorate-button .store-page-navigation .store-action-command:focus,
.store-decorate-button .store-product-list-item-action-list .store-action-command:focus {
    display: inline-block;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 1.1em;
    padding: .4em .5em .4em .5em;
}

.store-decorate-button .store-page-navigation .store-action-command,
.store-decorate-button .store-product-list-item-action-list .store-action-command {
    background-color: #f5f5f5;
    border: 1px solid #dedede;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    color: #565656;
}

.store-decorate-button .store-page-navigation .store-action-command:hover,
.store-decorate-button .store-product-list-item-action-list .store-action-command:hover {
    background-color: #dff4ff;
    border: 1px solid #c2e1ef;
    color: #369;
}

.store-decorate-button .store-page-navigation .store-action-command:active,
.store-decorate-button .store-product-list-item-action-list .store-action-command:active {
    background-color: #6299c5;
    border: 1px solid #6299c5;
    color: #999;
}


/* Added by BW */

.core-window{
    color:#999;

    h1{
        font-size: 2.5em;
    }

    h2{
        font-size: 2em;
    }

    h3{
        font-size:0.9em;
    }

}


.store-field-license-name-option-custom label::after {
    content: ' Other: ';
    //margin-left: -280px;
     }
.store-order-action-coupon-li  .store-action-input{
      height: 3em;
      width:150px;

}

.store-product-price{
	font-size:160%;
}




.core-section{
    h1{
        font-size: 2.5em;
    }

    h2{
        font-size: 2em;
    }
}

.fs-button05{
	width: 286px; 
	margin: 0 auto;
}


.store-order-item-offer-volume .store-order-item-offer-title{
  color:#999;
}

